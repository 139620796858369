<template>
  <validation-observer
    ref="courseSemesterSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="courseSemesterSaveModal"
      body-class="position-static"
      centered
      :title="`Cập nhật ${resourceName.fullName}`"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group
          label="Năm học"
          label-for="yearId"
        >
          <validation-provider
            #default="{ errors }"
            name="Năm học"
            rules="required"
          >
            <v-select
              v-model="targetData.yearId"
              :options="schoolYears"
              :reduce="option => option.value"
              @input="onSchoolYearsChange"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Ngày bắt đầu"
          label-for="startDate"
        >
          <validation-provider
            #default="{ errors }"
            name="Ngày bắt đầu"
            rules="required"
          >
            <b-form-datepicker
              id="startDate"
              v-model="targetData.startDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="vi"
              format="yyyy-mm-dd"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Ngày kết thúc"
          label-for="endDate"
        >
          <validation-provider
            #default="{ errors }"
            name="Ngày kết thúc"
            rules="required"
          >
            <b-form-datepicker
              id="endDate"
              v-model="targetData.endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="vi"
              format="yyyy-mm-dd"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Ngày bắt đầu nộp yêu cầu phúc khảo"
          label-for="checkExaminationStartDate"
        >
          <flat-pickr
            v-model="targetData.checkExaminationStartDate"
            class="form-control"
            :config="config"
          />
        </b-form-group>

        <b-form-group
          label="Ngày kết thúc nộp yêu cầu phúc khảo"
          label-for="checkExaminationEndDate"
        >
          <flat-pickr
            v-model="targetData.checkExaminationEndDate"
            class="form-control"
            :config="config"
          />
        </b-form-group>

        <b-form-group
          label="Trạng thái"
          label-for="status"
        >
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetData.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('courseSemesterSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BButton, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import vSelect from 'vue-select'
import { getSemesterLevel } from '@/utils'
import { Flag } from '@/const/flag'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'

export const Attr = Object.freeze({
  store: 'courseSemester',
})
export default {
  name: 'CourseSemesterSave',
  components: {
    flatPickr,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    extData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: 0,
        schoolYearId: null,
        startDate: '',
        endDate: '',
        checkExaminationStartDate: '',
        checkExaminationEndDate: '',
        orderNo: 0,
        status: Flag.DEACTIVATE,
      },
      config: {
        enableTime: true,
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'd/m/Y H:i',
        locale: FlatpickrLanguages.vn,
        static: true,
        time_24hr: true,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
      maxOrderNo: `${Attr.store}/maxOrderNo`,
      schoolYears: 'dropdown/schoolYears',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      updateData: `${Attr.store}/updateData`,
      getMaxOrderNo: `${Attr.store}/getMaxOrderNo`,
      getSchoolYears: 'dropdown/getSchoolYears',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.mainData) {
        this.targetData = this.mainData
        await this.getSchoolYears({})
      }
    },
    onHide() {
      this.$refs
        .courseSemesterSaveFormRef
        .reset()
      this.targetData = {
        id: 0,
        schoolYearId: null,
        startDate: '',
        endDate: '',
        checkExaminationStartDate: '',
        checkExaminationEndDate: '',
        orderNo: 0,
        status: Flag.DEACTIVATE,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .courseSemesterSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('courseSemesterSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    onSchoolYearsChange(id) {
      const selectedYear = this.schoolYears.find(year => year.value === id).label
      const semesterCategory = getSemesterLevel(this.mainData.rowNum)
      // eslint-disable-next-line no-nested-ternary
      const startMonth = (semesterCategory === 1) ? '09' : ((semesterCategory === 2) ? '02' : '07')
      // eslint-disable-next-line no-nested-ternary
      const endMonth = (semesterCategory === 1) ? '01' : ((semesterCategory === 2) ? '06' : '08')

      this.targetData.startDate = `${selectedYear.split('-')[0]}-${startMonth}-01`
      this.targetData.endDate = `${selectedYear.split('-')[1]}-${endMonth}-31`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.flatpickr-wrapper {
  width: 100%;
}
</style>
